





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { isToday, differenceInDays } from 'date-fns'
import { mapGetters } from 'vuex'
import { formatDate, formatDateMedium, arrivalMessage, shipmentDatesMessage } from '@/i18n'

import PageHeader from '@/components/PageHeader.vue'
import Order from '@/models/Order'
import { displayCostWithPendingItems } from '@/util/order'
import { AsyncStatusKey } from '@/vuex/asyncStatus'

@Component({
  components: { PageHeader },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
    ...mapGetters('orders', ['arrivalDateMessageFor']),
  },
})
export default class OrderDetailsHeader extends Vue {
  @Prop({ type: Object, required: true })
  order!: Order

  @Prop({ type: Boolean, required: false, default: false })
  showTracking!: boolean

  isInProgress!: (key: AsyncStatusKey) => boolean
  arrivalDateMessageFor!: (order: Order) => any

  get breadcrumbs() {
    return [
      {
        text: this.$t('Orders'),
        to: { name: 'orders' },
      },
      {
        text: this.showTracking ? this.arrivalMessageData?.dateMessage : this.breadcrumbText,
        to: { name: 'order-details' },
      },
    ]
  }

  get breadcrumbText() {
    const today = new Date()
    const { deliveredAt, hasMultipleShipments, shipments } = this.order
    if (!deliveredAt) return ''

    if (hasMultipleShipments) {
      return shipmentDatesMessage(shipments)
    } else if (isToday(deliveredAt)) {
      return this.$t('Today')
    } else if (
      differenceInDays(deliveredAt, today) < 6 &&
      differenceInDays(deliveredAt, today) > 0
    ) {
      return formatDate(deliveredAt, 'dddd')
    } else {
      return formatDateMedium(deliveredAt)
    }
  }

  get subtitle() {
    const cost = displayCostWithPendingItems(this.order)

    return [this.$tc('order.item.medicationCount', this.order.lineItemsInShipment.length), cost]
      .filter(Boolean)
      .join(', ')
  }

  get textFull() {
    const { deliveredAt, deliveryProblem, hasMultipleShipments, shipments } = this.order
    if (!deliveredAt) return ''

    if (hasMultipleShipments) {
      const [last] = shipments.slice(-1)
      return arrivalMessage(last.deliveredAt, shipmentDatesMessage(shipments), last.deliveryProblem)
    } else {
      return arrivalMessage(deliveredAt, undefined, deliveryProblem)
    }
  }

  get textShort() {
    const { deliveredAt, deliveryProblem, hasMultipleShipments, shipments } = this.order
    if (!deliveredAt) return ''

    if (hasMultipleShipments) {
      return shipmentDatesMessage(shipments)
    } else {
      return arrivalMessage(deliveredAt, undefined, deliveryProblem)
    }
  }

  get arrivalMessageData() {
    return this.arrivalDateMessageFor(this.order)
  }

  get arrivalMessage(): string {
    return this.arrivalMessageData?.arrivalMessage
  }
}
