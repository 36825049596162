









import { Component, Vue, Prop } from 'vue-property-decorator'
import AddressModel from '@/models/Address'

@Component
export default class Address extends Vue {
  @Prop()
  address!: AddressModel
}
