import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=9ac4e4a4&scoped=true&"
import script from "./OrderDetails.vue?vue&type=script&lang=ts&"
export * from "./OrderDetails.vue?vue&type=script&lang=ts&"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=9ac4e4a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ac4e4a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardBody } from 'bootstrap-vue'
installComponents(component, {BCardBody})
