

































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import Order from '@/models/Order'
import OrderDetailsMedicationList from '@/components/order-details/OrderDetailsMedicationList.vue'
import OrderDetailsShipment from '@/components/order-details/OrderDetailsShipment.vue'
import OrderDetailsPendingInsuranceNote from '@/components/order-details/OrderDetailsPendingInsuranceNote.vue'
import OrderDetailsShipmentLiterature from '@/components/order-details/OrderDetailsShipmentLiterature.vue'

@Component({
  components: {
    OrderDetailsShipmentLiterature,
    OrderDetailsMedicationList,
    OrderDetailsShipment,
    OrderDetailsPendingInsuranceNote,
  },
})
export default class OrderDetailsMedicationSection extends Vue {
  @Prop({ type: Object, required: true })
  order!: Order

  @Prop({ type: Boolean, required: false, default: false })
  showTracking!: boolean

  get header(): TranslateResult {
    if (!this.order.shipments) return this.$t('Medications')
    return this.$tc('order.medicationsHeader', this.order.shipments.length)
  }

  get subtotalLabelText() {
    return this.$t('Subtotal for {medicationCount}', {
      medicationCount: this.$tc('order.item.medicationCount', this.order.lineItemsWithCost.length),
    })
  }
}
