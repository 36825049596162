




































import { Component, Mixins } from 'vue-property-decorator'
import ProgressMixin from '@/mixins/ProgressMixin'
import ShipProgressBarNode from '@/components/ship-progress/ShipProgressBarNode.vue'
import ShipProgressBarEdge from '@/components/ship-progress/ShipProgressBarEdge.vue'

@Component({
  components: {
    ShipProgressBarNode,
    ShipProgressBarEdge,
  },
})
export default class ShipProgressBar extends Mixins(ProgressMixin) {
  currentStepLocal = 0
  animating = true
  animationDuration = 200 // ms

  async mounted() {
    this.increment()
  }

  increment() {
    setTimeout(() => {
      if (this.currentStepLocal < this.currentStep) {
        this.currentStepLocal += 1
        this.increment()
      } else {
        this.animating = false
      }
    }, this.animationDuration)
  }

  screenReaderLabelPrefix(i: number) {
    if (this.isPastStep(i)) return this.$t('Past step')
    if (this.isCurrentStep(i)) return this.$t('Current step')
    return this.$t('Future step')
  }

  isPastStep(i: number) {
    return i < this.currentStepLocal
  }

  isCurrentStep(i: number) {
    return !this.animating && i === this.currentStep
  }

  isFutureStep(i: number) {
    return i > this.currentStepLocal
  }
}
