










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class OrderDetailsPendingInsuranceNote extends Vue {
  @Prop({ type: Number, required: true })
  medicationCount!: number

  get medicationCountText() {
    return this.$tc('order.item.medicationCount', this.medicationCount)
  }
}
