















































import { Component, Vue, Prop } from 'vue-property-decorator'
import MedImage from '@/components/medications/MedImage.vue'
import MedStatusLine from '@/components/medications/MedStatusLine.vue'
import Order from '@/models/Order'
import OrderLineItem from '@/models/OrderLineItem'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import PacketTimes from '@/components/medications/PacketTimes.vue'

@Component({
  components: {
    MedImage,
    MedStatusLine,
    LinkArrowIcon,
    PacketTimes,
  },
})
export default class OrderDetailsMedicationListItem extends Vue {
  @Prop({ type: Object, required: true })
  lineItem!: OrderLineItem

  @Prop({ type: Object, required: true })
  order!: Order

  get doses() {
    const doses = this.lineItem.sortedDosesIn12HourFormat
    if (!doses) return []
    return doses.map(([time, quantity]) => ({ time, quantity }))
  }

  get paymentTypeForDisplay() {
    switch (this.lineItem.paymentType) {
      case 'insurance':
        return this.$t('Copay')
      case 'cash':
        return this.$t('Cash')
      default:
        return ''
    }
  }

  get toRoute() {
    if (this.order.status === 'delivered') return null
    if (!this.lineItem.dashboardMedication) return null
    if (this.lineItem.isPremiumDispenser) return null

    return {
      name: 'medication-details',
      params: {
        type: this.lineItem.dashboardMedication.type,
        id: this.lineItem.dashboardMedication.id,
      },
    }
  }
}
