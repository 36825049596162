






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ShipProgressBarNode extends Vue {
  @Prop()
  state!: string
}
