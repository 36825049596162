











import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('orders', ['getShipmentLiteratureFlag', 'getShipmentLiteratureURL']),
  },
})
export default class OrderDetailsShipmentLiterature extends Vue {
  getShipmentLiteratureFlag!: (shipmentId: string) => boolean | false
  getShipmentLiteratureURL!: (shipmentId: string) => string

  @Prop({ type: String, default: '' })
  shipmentId!: string

  shipLitFlag!: boolean | false
  shipLitURL: string | undefined

  loadLiterature() {
    return this.$store.dispatch('orders/loadShipmentLiterature', {
      id: this.shipmentId,
    })
  }

  async getValues() {
    await this.loadLiterature()
    this.shipLitFlag = this.getShipmentLiteratureFlag(this.shipmentId)
    this.shipLitURL = this.getShipmentLiteratureURL(this.shipmentId)
  }

  async created() {
    await this.getValues()
    this.$forceUpdate()
  }
}
