import { render, staticRenderFns } from "./OrderDetailsHeader.vue?vue&type=template&id=a6c1f9ec&scoped=true&"
import script from "./OrderDetailsHeader.vue?vue&type=script&lang=ts&"
export * from "./OrderDetailsHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6c1f9ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import IconBoxGeneric from '@/assets/icons/icon-box-generic.svg'
import IconBoxPacket from '@/assets/icons/icon-box-packet.svg'
installComponents(component, {IconBoxGeneric,IconBoxPacket})
