








import { Component, Vue, Prop } from 'vue-property-decorator'
import TrackingEvent from '@/models/Tracking/TrackingEvent'

@Component
export default class ShipProgressStatus extends Vue {
  @Prop({ required: true, type: TrackingEvent })
  step!: TrackingEvent
}
