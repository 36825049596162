import { render, staticRenderFns } from "./OrderDetailsPaymentList.vue?vue&type=template&id=7c801eb3&scoped=true&"
import script from "./OrderDetailsPaymentList.vue?vue&type=script&lang=ts&"
export * from "./OrderDetailsPaymentList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c801eb3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
installComponents(component, {BListGroup,BListGroupItem})
