































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Order from '@/models/Order'
import OrderPreferences from '@/models/OrderPreferences'
import OrderDetailsPaymentList from '@/components/order-details/OrderDetailsPaymentList.vue'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'
import PaymentMethodInfo from '@/components/PaymentMethodInfo.vue'

@Component({
  components: {
    OrderDetailsPaymentList,
    PaymentTypeImage,
    PaymentMethodInfo,
  },
})
export default class OrderDetailsPaymentSection extends Vue {
  @Prop({ type: Object, required: true })
  order!: Order

  @Prop({ type: Object, required: true })
  preferences!: OrderPreferences

  get hasPayments() {
    if (!this.order.paymentItems) return false

    return this.order.paymentItems.length
  }

  get paymentType() {
    if (this.preferences.defaultPaymentMethod.type === 'credit_card') {
      return this.$t('Credit Card')
    } else {
      return this.$t('Bank Account')
    }
  }

  get billingSchedule() {
    if (this.preferences.monthlyBillingDay) {
      return this.$t('We will charge your default payment method on the {dayOfMonth}', {
        dayOfMonth: this.preferences.monthlyBillingDay,
      })
    }

    return this.$t('We will charge your default payment method once your medications ship')
  }
}
