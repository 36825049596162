










import { Component, Vue, Prop } from 'vue-property-decorator'
import Order from '@/models/Order'
import OrderLineItem from '@/models/OrderLineItem'
import OrderDetailsMedicationListItem from '@/components/order-details/OrderDetailsMedicationListItem.vue'

@Component({
  components: {
    OrderDetailsMedicationListItem,
  },
})
export default class OrderDetailsMedicationList extends Vue {
  @Prop({ type: Object, required: true })
  order!: Order

  @Prop({ type: Array, required: true })
  lineItems!: OrderLineItem[]
}
