import { render, staticRenderFns } from "./OrderDetailsMedicationListItem.vue?vue&type=template&id=10c5acc2&scoped=true&"
import script from "./OrderDetailsMedicationListItem.vue?vue&type=script&lang=ts&"
export * from "./OrderDetailsMedicationListItem.vue?vue&type=script&lang=ts&"
import style0 from "./OrderDetailsMedicationListItem.vue?vue&type=style&index=0&id=10c5acc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c5acc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import { BMediaBody } from 'bootstrap-vue'
installComponents(component, {BListGroupItem,BMedia,BMediaBody})
