




import { Component, Vue, Prop } from 'vue-property-decorator'
import PaymentMethod from '@/models/PaymentMethod'
import { BasicCardInfo } from '@/models/StripeCard'
import { BasicBankAccountInfo } from '@/models/StripeBankAccount'

@Component
export default class PaymentMethodInfo extends Vue {
  @Prop({ type: Object, required: true })
  paymentMethod!: PaymentMethod | BasicCardInfo | BasicBankAccountInfo

  get paymentType() {
    if (
      (this.paymentMethod instanceof PaymentMethod && this.paymentMethod.type === 'credit_card') ||
      this.paymentMethod instanceof BasicCardInfo
    ) {
      return this.$t('Credit Card')
    } else {
      return this.$t('Bank Account')
    }
  }
}
