import { render, staticRenderFns } from "./OrderDetailsPaymentSection.vue?vue&type=template&id=6d460e6e&scoped=true&"
import script from "./OrderDetailsPaymentSection.vue?vue&type=script&lang=ts&"
export * from "./OrderDetailsPaymentSection.vue?vue&type=script&lang=ts&"
import style0 from "./OrderDetailsPaymentSection.vue?vue&type=style&index=0&id=6d460e6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d460e6e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardBody } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import IconInsurance from '@/assets/icons/icon-insurance.svg'
installComponents(component, {BCardBody,BListGroup,BListGroupItem,BMedia,IconInsurance})
