




















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { isPast } from 'date-fns'
import { mapGetters } from 'vuex'
import { formatDateLong } from '@/i18n'
import Order from '@/models/Order'
import OrderPreferences from '@/models/OrderPreferences'
import Card from '@/components/Card.vue'
import Address from '@/components/Address.vue'
import OrderDetailsHeader from '@/components/order-details/OrderDetailsHeader.vue'
import OrderDetailsShipmentsSection from '@/components/order-details/OrderDetailsShipmentsSection.vue'
import OrderDetailsPaymentSection from '@/components/order-details/OrderDetailsPaymentSection.vue'
import OrderTrackingLink from '@/components/OrderTrackingLink.vue'
import DeliveryProblemAlert from '@/components/DeliveryProblemAlert.vue'
import ShipProgress from '@/components/ship-progress/ShipProgress.vue'
import { AsyncStatusKey } from '@/vuex/asyncStatus'

@Component({
  components: {
    Card,
    Address,
    OrderDetailsHeader,
    OrderDetailsShipmentsSection,
    OrderDetailsPaymentSection,
    OrderTrackingLink,
    DeliveryProblemAlert,
    ShipProgress,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class OrderDetailsView extends Vue {
  @Prop({ type: Object, required: true })
  order!: Order

  @Prop({ type: Object, required: true })
  preferences!: OrderPreferences

  @Prop({ type: Boolean, required: false, default: false })
  showTracking!: boolean

  isInProgress!: (key: AsyncStatusKey) => boolean

  get allowChanges() {
    if (!this.order.changesDeadlineDate) return false

    return this.order.changesDeadlineDate > new Date()
  }

  get changesDeadline() {
    if (!this.order.changesDeadlineDate) return ''

    return formatDateLong(this.order.changesDeadlineDate)
  }

  get arrivesByDate() {
    if (!this.order.deliveredAt) return false
    if (isPast(this.order.deliveredAt)) return false
    return formatDateLong(this.order.deliveredAt)
  }

  get showFallbackTracking() {
    return this.order.showFallbackTracking
  }
}
