


















import { Component, Vue, Prop } from 'vue-property-decorator'
import Order from '@/models/Order'
import Shipment from '@/models/Shipment'
import { arrivalMessage } from '@/i18n'
import OrderDetailsMedicationList from '@/components/order-details/OrderDetailsMedicationList.vue'
import OrderTrackingLink from '@/components/OrderTrackingLink.vue'
import ShipProgress from '@/components/ship-progress/ShipProgress.vue'
import OrderDetailsShipmentLiterature from '@/components/order-details/OrderDetailsShipmentLiterature.vue'

@Component({
  components: {
    OrderTrackingLink,
    OrderDetailsMedicationList,
    ShipProgress,
    OrderDetailsShipmentLiterature,
  },
})
export default class OrderDetailsShipment extends Vue {
  @Prop({ type: Object, required: true })
  order!: Order

  @Prop({ type: Object, required: true })
  shipment!: Shipment

  @Prop({ type: Boolean, required: false, default: false })
  showTracking!: boolean

  get headerMessage() {
    const { deliveredAt } = this.shipment
    return arrivalMessage(deliveredAt, undefined, this.order.deliveryProblem)
  }
}
