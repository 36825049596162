




import { Component, Vue, Prop } from 'vue-property-decorator'

const UNKNOWN_PAYMENT_TYPE_URL = '/img/payment/unknown.png'

@Component
export default class PaymentTypeImage extends Vue {
  @Prop({ type: String })
  src?: string

  @Prop(String)
  brand?: string

  imgError = false

  get iconSrc() {
    if (this.imgError) {
      return UNKNOWN_PAYMENT_TYPE_URL
    } else if (this.brand) {
      return `/img/payment/${this.brand.replace(' ', '-').toLowerCase()}.png`
    } else if (this.src) {
      return this.src
    }
    return UNKNOWN_PAYMENT_TYPE_URL
  }
}
