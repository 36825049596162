




























import { mapGetters } from 'vuex'
import { Component, Vue, Prop } from 'vue-property-decorator'
import TrackingHistory from '@/models/Tracking/TrackingHistory'
import Shipment from '@/models/Shipment'
import ShipProgressBar from '@/components/ship-progress/ShipProgressBar.vue'
import ShipProgressStatus from '@/components/ship-progress/ShipProgressStatus.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { AsyncStatusKey } from '@/vuex/asyncStatus'
import { formatDateDistance } from '@/i18n'

export enum LastMileStatuses {
  OUT_FOR_DELIVERY,
  DELIVERED,
}

@Component({
  components: {
    ShipProgressBar,
    ShipProgressStatus,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
    ...mapGetters('tracking', ['trackingHistoryFor']),
  },
})
export default class ShipProgress extends Vue {
  isInProgress!: (key: AsyncStatusKey) => boolean
  trackingHistoryFor!: (shipment: Shipment) => TrackingHistory

  @Prop({ type: Shipment, required: true })
  shipment!: Shipment

  get steps() {
    return this.trackingHistory.trackingEvents.filter(step => !step.hide)
  }

  get currentIndex(): number {
    const { currentStatusEvent } = this.trackingHistory
    const { eventDate, shortStatus, eventDescriptionTranslatorKey } = currentStatusEvent

    const currentIndex = this.steps.findIndex(step => {
      return (
        step.eventDate?.getTime() === eventDate?.getTime() &&
        step.shortStatus === shortStatus &&
        step.eventDescriptionTranslatorKey === eventDescriptionTranslatorKey
      )
    })
    if (currentIndex < 0) return 0
    return currentIndex
  }

  get showSpinner(): boolean {
    return this.isInProgress('tracking/load')
  }

  get trackingHistory(): TrackingHistory {
    return this.trackingHistoryFor(this.shipment)
  }

  get currentStatusEvent() {
    return this.trackingHistory?.currentStatusEvent
  }

  get currentStatusMessage() {
    if (!this.currentStatusEvent) return this.$t('Ordered')
    const { eventDescriptionTranslatorKey, shortStatus } = this.currentStatusEvent
    const status = this.$t(eventDescriptionTranslatorKey)
    if (shortStatus in LastMileStatuses) {
      const carrier = this.trackingHistory.carrierDisplayName
      if (status && carrier) {
        return this.$t('{status} by {carrier}', { status, carrier })
      } else if (status && !carrier) {
        return status
      }
    }
    return this.$t('Ordered')
  }

  get trackingNumber() {
    const { trackingNumber } = this.trackingHistory
    return trackingNumber
  }

  get lastUpdatedMessage() {
    const eventDate = this.currentStatusEvent?.eventDate
    if (!eventDate) return null
    const time = formatDateDistance(new Date(), eventDate, {
      addSuffix: true,
    })
    return this.$t('Updated {time}', { time })
  }

  get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone?.replace('_', ' ')
  }
}
