













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class OrderTrackingLink extends Vue {
  @Prop({ type: String })
  trackingUrl?: string
}
