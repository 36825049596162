

























































import { Component, Vue, Prop } from 'vue-property-decorator'
import Order from '@/models/Order'
import OrderDetailsPaymentListItem from '@/components/order-details/OrderDetailsPaymentListItem.vue'

@Component({
  components: {
    OrderDetailsPaymentListItem,
  },
})
export default class OrderDetailsPaymentList extends Vue {
  @Prop()
  order!: Order
}
