

























import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import OrderDetails from '@/components/OrderDetails.vue'
import Order from '@/models/Order'
import OrderPreferences from '@/models/OrderPreferences'
import User from '@/models/User'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import NotFound from '@/components/NotFound.vue'
import featureFlags from '@/util/featureFlags'

@Component({
  components: {
    OrderDetails,
    LoadingSpinner,
    NotFound,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapState('orders', ['ordersById', 'preferences']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class OrderDetailsView extends Vue {
  me!: User
  preferences!: OrderPreferences
  ordersById!: { [id: string]: Order }
  isInProgress!: (key: string, id: string) => boolean
  getError!: (key: string, id: string) => Error
  showTracking: boolean = false

  @Prop(String)
  id!: string

  get order(): Order {
    return this.ordersById[this.id]
  }

  get hasOrder(): boolean {
    return !!this.order
  }

  get onHoldError(): boolean {
    return !this.order && !!this.getError('orders/loadOne', 'upcoming') && this.me.onShippingHold
  }

  get notFoundError(): boolean {
    return !this.order && !!this.getError('orders/loadOne', this.id)
  }

  get loading(): boolean {
    return !this.order && this.isInProgress('orders/loadOne', this.id)
  }

  get hasPreferences(): boolean {
    return !!this.preferences
  }

  async created(): Promise<void> {
    const { id } = this
    this.$store.dispatch('orders/loadPreferences')
    this.$store.dispatch('orders/loadOne', { id })

    if (await featureFlags.enabled('enable-tracking')) {
      this.showTracking = true
    }
  }
}
