import { render, staticRenderFns } from "./OrderDetailsMedicationList.vue?vue&type=template&id=11834ddc&scoped=true&"
import script from "./OrderDetailsMedicationList.vue?vue&type=script&lang=ts&"
export * from "./OrderDetailsMedicationList.vue?vue&type=script&lang=ts&"
import style0 from "./OrderDetailsMedicationList.vue?vue&type=style&index=0&id=11834ddc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11834ddc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroup } from 'bootstrap-vue'
installComponents(component, {BListGroup})
