
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatDateMedium } from '@/i18n'
import OrderPaymentItem from '@/models/OrderPaymentItem'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'

@Component({
  components: {
    PaymentTypeImage,
  },
})
export default class OrderDetailsPaymentListItem extends Vue {
  @Prop()
  paymentItem!: OrderPaymentItem

  @Prop(Boolean)
  isRefund!: boolean

  get chargeDate() {
    return formatDateMedium(this.paymentItem.createdAt)
  }
}
