






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ShipProgressBarEdge extends Vue {
  @Prop(Boolean)
  active!: boolean

  @Prop(Number)
  progress!: number

  get _progress() {
    if (this.active) return 100
    if (this.progress) return this.progress
    return 0
  }

  get barTranslate() {
    return `translateY(-${100 - this._progress}%)`
  }
}
