














import { Component, Vue, Prop } from 'vue-property-decorator'
import Dose from '@/models/PrescriptionDose'

@Component
export default class PacketTime extends Vue {
  @Prop({ type: Array, default: () => [] })
  doses!: Dose[]

  accessibleLabel({ time, quantity }: Dose) {
    return `${quantity} at ${time}`
  }
}
